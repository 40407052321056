import { useMutation, MutationHookOptions } from '@apollo/client';
import MUTATION from '../operations/ExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet.graphql';
import {
  Mutation,
  MutationexportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetArgs
} from '../../__generated__/globalTypes';

export const useExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetMutation = (
  options?: MutationHookOptions<Mutation, MutationexportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetArgs>
) => {
  const [callExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet, result] = useMutation<
    Mutation,
    MutationexportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetArgs
  >(MUTATION, options);

  return { callExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet, ...result };
};

export type ExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetMutationFunction =
  Mutation['exportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet'];
