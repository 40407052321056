export const namedOperations = {
  Query: {
    AccountAnalyticDetailsV2: 'AccountAnalyticDetailsV2',
    AccountAnalyticsBasicDetailsV2: 'AccountAnalyticsBasicDetailsV2',
    Address: 'Address',
    Advertiser: 'Advertiser',
    AdvertiserForAddEngagement: 'AdvertiserForAddEngagement',
    AdvertiserForAddMarketplace: 'AdvertiserForAddMarketplace',
    Agency: 'Agency',
    AgencyBusinessAssociateCompanies: 'AgencyBusinessAssociateCompanies',
    AgencyLogo: 'AgencyLogo',
    AiChatModel: 'AiChatModel',
    AllAccountAnalyticsForAdminV2: 'AllAccountAnalyticsForAdminV2',
    AllAccountAnalyticsForAdvertiserV2: 'AllAccountAnalyticsForAdvertiserV2',
    AllAdvertiserPics: 'AllAdvertiserPics',
    AllAdvertisers: 'AllAdvertisers',
    AllAdvertisersForAnalyticsSearch: 'AllAdvertisersForAnalyticsSearch',
    AllAdvertisersForSearch: 'AllAdvertisersForSearch',
    AllAgencies: 'AllAgencies',
    AllAgenciesForSearch: 'AllAgenciesForSearch',
    AllAgencyUsers: 'AllAgencyUsers',
    AllAnalyticsSubscriptionPlans: 'AllAnalyticsSubscriptionPlans',
    AllApplicantInfluencersForMarketplace: 'AllApplicantInfluencersForMarketplace',
    AllAvailableCountries: 'AllAvailableCountries',
    AllBankBranches: 'AllBankBranches',
    AllBanks: 'AllBanks',
    AllCampaignCategories: 'AllCampaignCategories',
    AllCampaignPosts: 'AllCampaignPosts',
    AllCampaignsForFilter: 'AllCampaignsForFilter',
    AllCampaignsReport: 'AllCampaignsReport',
    AllCampaignsSummary: 'AllCampaignsSummary',
    AllCategories: 'AllCategories',
    AllCountries: 'AllCountries',
    AllDouyinInfluencers: 'AllDouyinInfluencers',
    AllEngagementPosts: 'AllEngagementPosts',
    AllEngagementPostsForInsightCheck: 'AllEngagementPostsForInsightCheck',
    AllEngagements: 'AllEngagements',
    AllEngagementsForInsightCheck: 'AllEngagementsForInsightCheck',
    AllExistingPackages: 'AllExistingPackages',
    AllFacebookAccountInfluencersV2: 'AllFacebookAccountInfluencersV2',
    AllFacebookPageInfluencersV2: 'AllFacebookPageInfluencersV2',
    AllInfluencerAccountPosts: 'AllInfluencerAccountPosts',
    AllInfluencerCategories: 'AllInfluencerCategories',
    AllInfluencerManagers: 'AllInfluencerManagers',
    AllInfluencerPostsV3: 'AllInfluencerPostsV3',
    AllInfluencersForMarketplaceV2: 'AllInfluencersForMarketplaceV2',
    AllInfluencersV4: 'AllInfluencersV4',
    AllInstagramAmbassadorPosts: 'AllInstagramAmbassadorPosts',
    AllInstagramCompareFeedPosts: 'AllInstagramCompareFeedPosts',
    AllInstagramComparePostsInDate: 'AllInstagramComparePostsInDate',
    AllInstagramFeedPosts: 'AllInstagramFeedPosts',
    AllInstagramHashtagFeedPosts: 'AllInstagramHashtagFeedPosts',
    AllInstagramInfluencersV3: 'AllInstagramInfluencersV3',
    AllInstagramInteractionUsers: 'AllInstagramInteractionUsers',
    AllInstagramMentionedPosts: 'AllInstagramMentionedPosts',
    AllInstagramPostsInDate: 'AllInstagramPostsInDate',
    AllInstagramReelPosts: 'AllInstagramReelPosts',
    AllInstagramSimilarInfluencersV3: 'AllInstagramSimilarInfluencersV3',
    AllInstagramStoryPosts: 'AllInstagramStoryPosts',
    AllInstagramTaggedPosts: 'AllInstagramTaggedPosts',
    AllMarketplacePosts: 'AllMarketplacePosts',
    AllMarketplaces: 'AllMarketplaces',
    AllMarketplacesForReorder: 'AllMarketplacesForReorder',
    AllNeedReviewMarketplacePosts: 'AllNeedReviewMarketplacePosts',
    AllPackageCompanies: 'AllPackageCompanies',
    AllPackages: 'AllPackages',
    AllPackagesCount: 'AllPackagesCount',
    AllPartnerAgencyCompany: 'AllPartnerAgencyCompany',
    AllPartnerAgencyUsers: 'AllPartnerAgencyUsers',
    AllPartners: 'AllPartners',
    AllPayments: 'AllPayments',
    AllPicUsers: 'AllPicUsers',
    AllRegions: 'AllRegions',
    AllStaffs: 'AllStaffs',
    AllTalentAgencies: 'AllTalentAgencies',
    AllTalentAgenciesOrPartners: 'AllTalentAgenciesOrPartners',
    AllTalentAgencyCompany: 'AllTalentAgencyCompany',
    AllTalentAgencyUsers: 'AllTalentAgencyUsers',
    AllThreadsInfluencers: 'AllThreadsInfluencers',
    AllTiktokAdReportSummary: 'AllTiktokAdReportSummary',
    AllTiktokAdsReport: 'AllTiktokAdsReport',
    AllTiktokInfluencers: 'AllTiktokInfluencers',
    AllTiktokInfluencersV2: 'AllTiktokInfluencersV2',
    AllTiktokProductCategory: 'AllTiktokProductCategory',
    AllTiktokSimilarInfluencers: 'AllTiktokSimilarInfluencers',
    AllTiktokSpecialCampaigns: 'AllTiktokSpecialCampaigns',
    AllTwitterInfluencers: 'AllTwitterInfluencers',
    AllTwitterSimilarInfluencers: 'AllTwitterSimilarInfluencers',
    AllUsers: 'AllUsers',
    AllXHSInfluencers: 'AllXHSInfluencers',
    AllYoutubeCmsConfirmedAssets: 'AllYoutubeCmsConfirmedAssets',
    AllYoutubeCmsConfirmedChannels: 'AllYoutubeCmsConfirmedChannels',
    AllYoutubeCmsEstimateAssets: 'AllYoutubeCmsEstimateAssets',
    AllYoutubeCmsEstimateChannels: 'AllYoutubeCmsEstimateChannels',
    AllYoutubeInfluencersV3: 'AllYoutubeInfluencersV3',
    AllYoutubeSimilarInfluencers: 'AllYoutubeSimilarInfluencers',
    AnalyticsSocialAuthRedirectUrl: 'AnalyticsSocialAuthRedirectUrl',
    AnalyticsSubscriptionPlanByAdvId: 'AnalyticsSubscriptionPlanByAdvId',
    AutoManagedCommissionFixedDates: 'AutoManagedCommissionFixedDates',
    AutoManagedCommissionInfluencers: 'AutoManagedCommissionInfluencers',
    AutoManagedJoinedInfluencers: 'AutoManagedJoinedInfluencers',
    AutoManagedReportByDate: 'AutoManagedReportByDate',
    AutoManagedReportByInfluencer: 'AutoManagedReportByInfluencer',
    AutoManagedReportSummary: 'AutoManagedReportSummary',
    BillingInformation: 'BillingInformation',
    BrandAccounts: 'BrandAccounts',
    CampaignCheckoutSessionLink: 'CampaignCheckoutSessionLink',
    CampaignHashtagCloud: 'CampaignHashtagCloud',
    CampaignsChartForAllTime: 'CampaignsChartForAllTime',
    CheckoutSessionLink: 'CheckoutSessionLink',
    CmsInvoiceDownloadUrl: 'CmsInvoiceDownloadUrl',
    CustomerPortalLink: 'CustomerPortalLink',
    DouyinAccountInterest: 'DouyinAccountInterest',
    DouyinProfileAudience: 'DouyinProfileAudience',
    Engagement: 'Engagement',
    EngagementCampaignInfluencerDaily: 'EngagementCampaignInfluencerDaily',
    EngagementCampaignInfluencerPostDaily: 'EngagementCampaignInfluencerPostDaily',
    EngagementCampaignPostReportAudienceBreakdown: 'EngagementCampaignPostReportAudienceBreakdown',
    EngagementCampaignReport: 'EngagementCampaignReport',
    EngagementCampaignReportGrid: 'EngagementCampaignReportGrid',
    EngagementCampaignReportSummary: 'EngagementCampaignReportSummary',
    EngagementCampaignTotalDaily: 'EngagementCampaignTotalDaily',
    EngagementCampaignTrafficSource: 'EngagementCampaignTrafficSource',
    EngagementDraftPostHistory: 'EngagementDraftPostHistory',
    EngagementPostComments: 'EngagementPostComments',
    EngagementPostDetail: 'EngagementPostDetail',
    EngagementPostInfluencers: 'EngagementPostInfluencers',
    EngagementPostMetricsForInsightCheck: 'EngagementPostMetricsForInsightCheck',
    EngagementPostTrafficSource: 'EngagementPostTrafficSource',
    EngagementPostV2: 'EngagementPostV2',
    EngagementTracking: 'EngagementTracking',
    EngagementXhsPostMetricsForInsight: 'EngagementXhsPostMetricsForInsight',
    ExistingProfileTags: 'ExistingProfileTags',
    FacebookPageAnalyticsAudience: 'FacebookPageAnalyticsAudience',
    FacebookPageAnalyticsOverview: 'FacebookPageAnalyticsOverview',
    FacebookPageAnalyticsPost: 'FacebookPageAnalyticsPost',
    FacebookPageAnalyticsPosts: 'FacebookPageAnalyticsPosts',
    FacebookPageAnalyticsPostsAnalytics: 'FacebookPageAnalyticsPostsAnalytics',
    FacebookPageAnalyticsPostsByDate: 'FacebookPageAnalyticsPostsByDate',
    FindJoinedCampaigns: 'FindJoinedCampaigns',
    GenerateAgencyLogoSignedUrl: 'GenerateAgencyLogoSignedUrl',
    GenerateMaterialSignedUrl: 'GenerateMaterialSignedUrl',
    GeneratePostFileSignedUrls: 'GeneratePostFileSignedUrls',
    GenerateThumbnailSignedUrl: 'GenerateThumbnailSignedUrl',
    GenerateUploadReportSignedUrl: 'GenerateUploadReportSignedUrl',
    GetAdvertiserSettingsInfo: 'GetAdvertiserSettingsInfo',
    GetAutoManagedCampaignById: 'GetAutoManagedCampaignById',
    GetAutoManagedCampaigns: 'GetAutoManagedCampaigns',
    GetAutoManagedCampaignsCount: 'GetAutoManagedCampaignsCount',
    GetCouponsList: 'GetCouponsList',
    GetCurrentUserCurrency: 'GetCurrentUserCurrency',
    GetGeneratedMarketplaceCampaignDetail: 'GetGeneratedMarketplaceCampaignDetail',
    GetInfluencerRateCard: 'GetInfluencerRateCard',
    GetLogo: 'GetLogo',
    GetUserDefaultPermissions: 'GetUserDefaultPermissions',
    GetUserPermissions: 'GetUserPermissions',
    GetYoutubeMCNRedirectUrlForAdmin: 'GetYoutubeMCNRedirectUrlForAdmin',
    HubspotCompanyList: 'HubspotCompanyList',
    HubspotCompanyName: 'HubspotCompanyName',
    HubspotDealName: 'HubspotDealName',
    HubspotDealsList: 'HubspotDealsList',
    InfluencerAllInstagramFeedPosts: 'InfluencerAllInstagramFeedPosts',
    InfluencerAllInstagramPostsInDate: 'InfluencerAllInstagramPostsInDate',
    InfluencerAllInstagramReelPosts: 'InfluencerAllInstagramReelPosts',
    InfluencerAllInstagramStoryPosts: 'InfluencerAllInstagramStoryPosts',
    InfluencerAnalyticsFacebookPageAccessTokenWarning: 'InfluencerAnalyticsFacebookPageAccessTokenWarning',
    InfluencerAnalyticsInstagramAccessTokenWarning: 'InfluencerAnalyticsInstagramAccessTokenWarning',
    InfluencerAnalyticsMultipleInstagramAccessTokenWarning: 'InfluencerAnalyticsMultipleInstagramAccessTokenWarning',
    InfluencerAnalyticsTwitterAccessTokenWarning: 'InfluencerAnalyticsTwitterAccessTokenWarning',
    InfluencerAnalyticsTwitterRefreshAvailability: 'InfluencerAnalyticsTwitterRefreshAvailability',
    InfluencerCampaignList: 'InfluencerCampaignList',
    InfluencerCampaignListByPromotionMethod: 'InfluencerCampaignListByPromotionMethod',
    InfluencerCampaignSummary: 'InfluencerCampaignSummary',
    InfluencerFacebookPageAnalyticsAudience: 'InfluencerFacebookPageAnalyticsAudience',
    InfluencerFacebookPageAnalyticsOverview: 'InfluencerFacebookPageAnalyticsOverview',
    InfluencerFacebookPageAnalyticsPost: 'InfluencerFacebookPageAnalyticsPost',
    InfluencerFacebookPageAnalyticsPosts: 'InfluencerFacebookPageAnalyticsPosts',
    InfluencerFacebookPageAnalyticsPostsAnalytics: 'InfluencerFacebookPageAnalyticsPostsAnalytics',
    InfluencerFacebookPageAnalyticsPostsByDate: 'InfluencerFacebookPageAnalyticsPostsByDate',
    InfluencerHasEstimateApi: 'InfluencerHasEstimateApi',
    InfluencerInstagramAnalyticsPostDetails: 'InfluencerInstagramAnalyticsPostDetails',
    InfluencerInstagramAnalyticsReelDetails: 'InfluencerInstagramAnalyticsReelDetails',
    InfluencerInstagramAnalyticsStoryDetails: 'InfluencerInstagramAnalyticsStoryDetails',
    InfluencerInstagramDashboardFollowerAnalytics: 'InfluencerInstagramDashboardFollowerAnalytics',
    InfluencerInstagramDashboardOverview: 'InfluencerInstagramDashboardOverview',
    InfluencerInterests: 'InfluencerInterests',
    InfluencerMultipleSocialAccountsCount: 'InfluencerMultipleSocialAccountsCount',
    InfluencerPostDetail: 'InfluencerPostDetail',
    InfluencerProfileAudience: 'InfluencerProfileAudience',
    InfluencerProfileDetailForEngagementProposal: 'InfluencerProfileDetailForEngagementProposal',
    InfluencerProfileDetailV2: 'InfluencerProfileDetailV2',
    InfluencerProfileFollowerGrowth: 'InfluencerProfileFollowerGrowth',
    InfluencerProfileForEngagementProposal: 'InfluencerProfileForEngagementProposal',
    InfluencerProfilePosts: 'InfluencerProfilePosts',
    InfluencerProfileV2: 'InfluencerProfileV2',
    InfluencerTwitterAnalyticsOverview: 'InfluencerTwitterAnalyticsOverview',
    InfluencerTwitterAnalyticsPost: 'InfluencerTwitterAnalyticsPost',
    InfluencerTwitterAnalyticsPosts: 'InfluencerTwitterAnalyticsPosts',
    InfluencerTwitterAnalyticsPostsByDate: 'InfluencerTwitterAnalyticsPostsByDate',
    InfluencerUpdateDetailV2: 'InfluencerUpdateDetailV2',
    InfluencerWhitelist: 'InfluencerWhitelist',
    InfluencerYoutubeAnalyticsAudience: 'InfluencerYoutubeAnalyticsAudience',
    InfluencerYoutubeAnalyticsChannelDetails: 'InfluencerYoutubeAnalyticsChannelDetails',
    InfluencerYoutubeAnalyticsCompareAccountCards: 'InfluencerYoutubeAnalyticsCompareAccountCards',
    InfluencerYoutubeAnalyticsCompareRelatedPosts: 'InfluencerYoutubeAnalyticsCompareRelatedPosts',
    InfluencerYoutubeAnalyticsOverview: 'InfluencerYoutubeAnalyticsOverview',
    InfluencerYoutubeAnalyticsPost: 'InfluencerYoutubeAnalyticsPost',
    InfluencerYoutubeAnalyticsPosts: 'InfluencerYoutubeAnalyticsPosts',
    InfluencerYoutubeAnalyticsPostsByDate: 'InfluencerYoutubeAnalyticsPostsByDate',
    InfluencerYoutubeAnalyticsPostsStatistics: 'InfluencerYoutubeAnalyticsPostsStatistics',
    InfluencerYoutubeAnalyticsRelatedPosts: 'InfluencerYoutubeAnalyticsRelatedPosts',
    InfluencerYoutubeAnalyticsShorts: 'InfluencerYoutubeAnalyticsShorts',
    InfluencerYoutubeAnalyticsSponsoredPosts: 'InfluencerYoutubeAnalyticsSponsoredPosts',
    InfluencerYoutubeAnalyticsTagsTopRanking: 'InfluencerYoutubeAnalyticsTagsTopRanking',
    InfluencerYoutubeAnalyticsTagsTopRankingCount: 'InfluencerYoutubeAnalyticsTagsTopRankingCount',
    InfluencerYoutubeCompareAllPostsInDate: 'InfluencerYoutubeCompareAllPostsInDate',
    InfluencerYoutubeCompareOverview: 'InfluencerYoutubeCompareOverview',
    InfluencerYoutubeComparePostById: 'InfluencerYoutubeComparePostById',
    InfluencerYoutubeComparePostList: 'InfluencerYoutubeComparePostList',
    InfluencerYoutubeComparePosts: 'InfluencerYoutubeComparePosts',
    InfluencerYoutubeCompareTagRanking: 'InfluencerYoutubeCompareTagRanking',
    InstagramAmbassadorPostDetails: 'InstagramAmbassadorPostDetails',
    InstagramAnalyticsHashtags: 'InstagramAnalyticsHashtags',
    InstagramAnalyticsListComments: 'InstagramAnalyticsListComments',
    InstagramAnalyticsPostDetails: 'InstagramAnalyticsPostDetails',
    InstagramAnalyticsReelDetails: 'InstagramAnalyticsReelDetails',
    InstagramAnalyticsStoryDetails: 'InstagramAnalyticsStoryDetails',
    InstagramCompareAccounts: 'InstagramCompareAccounts',
    InstagramCompareFollowers: 'InstagramCompareFollowers',
    InstagramCompareGetAccounts: 'InstagramCompareGetAccounts',
    InstagramCompareOverview: 'InstagramCompareOverview',
    InstagramComparePostDetails: 'InstagramComparePostDetails',
    InstagramComparePosts: 'InstagramComparePosts',
    InstagramDashboardFollowerAnalytics: 'InstagramDashboardFollowerAnalytics',
    InstagramDashboardOverview: 'InstagramDashboardOverview',
    InstagramDashboardPostAnalytics: 'InstagramDashboardPostAnalytics',
    InstagramHashtagFeedPostsV2: 'InstagramHashtagFeedPostsV2',
    InstagramHashtagPostDetails: 'InstagramHashtagPostDetails',
    InstagramHashtagPosts: 'InstagramHashtagPosts',
    InstagramHashtagPostsV2: 'InstagramHashtagPostsV2',
    InstagramHashtagsOverview: 'InstagramHashtagsOverview',
    InstagramInteractionMentionedPostDetails: 'InstagramInteractionMentionedPostDetails',
    InstagramInteractionOverview: 'InstagramInteractionOverview',
    InstagramInteractionPosts: 'InstagramInteractionPosts',
    InstagramInteractionTaggedPostDetails: 'InstagramInteractionTaggedPostDetails',
    InstagramUGCManagementBasicInfo: 'InstagramUGCManagementBasicInfo',
    InstagramUGCManagementListPosts: 'InstagramUGCManagementListPosts',
    InstagramUGCManagementPostDetails: 'InstagramUGCManagementPostDetails',
    InstagramUserHashtagPostList: 'InstagramUserHashtagPostList',
    InstagramUserHashtags: 'InstagramUserHashtags',
    Marketplace: 'Marketplace',
    MarketplaceAffiliateCommissionFixedDates: 'MarketplaceAffiliateCommissionFixedDates',
    MarketplaceAffiliateCommissionInfluencers: 'MarketplaceAffiliateCommissionInfluencers',
    MarketplaceAffiliateJoinedInfluencers: 'MarketplaceAffiliateJoinedInfluencers',
    MarketplaceAffiliateReportByDate: 'MarketplaceAffiliateReportByDate',
    MarketplaceAffiliateReportByInfluencer: 'MarketplaceAffiliateReportByInfluencer',
    MarketplaceAffiliateReportSummary: 'MarketplaceAffiliateReportSummary',
    MarketplaceCampaignInfluencerDaily: 'MarketplaceCampaignInfluencerDaily',
    MarketplaceCampaignReport: 'MarketplaceCampaignReport',
    MarketplaceCampaignReportGrid: 'MarketplaceCampaignReportGrid',
    MarketplaceCampaignReportSummary: 'MarketplaceCampaignReportSummary',
    MarketplaceCampaignTotalDaily: 'MarketplaceCampaignTotalDaily',
    MarketplaceDraftPost: 'MarketplaceDraftPost',
    MarketplaceGoogleSlidesHistory: 'MarketplaceGoogleSlidesHistory',
    MarketplaceHashtagCloud: 'MarketplaceHashtagCloud',
    MarketplacePost: 'MarketplacePost',
    MarketplacePostComments: 'MarketplacePostComments',
    MarketplacePostHistory: 'MarketplacePostHistory',
    MarketplacePostsNoRevenue: 'MarketplacePostsNoRevenue',
    MarketplaceTracking: 'MarketplaceTracking',
    NetsuiteVendorList: 'NetsuiteVendorList',
    NetsuiteVendorName: 'NetsuiteVendorName',
    OpenAiMessage: 'OpenAiMessage',
    Package: 'Package',
    PackageInfluencers: 'PackageInfluencers',
    PackageProposal: 'PackageProposal',
    PackageProposalSummary: 'PackageProposalSummary',
    PartnerAgency: 'PartnerAgency',
    PartnerAgencyBusinessAssociates: 'PartnerAgencyBusinessAssociates',
    PartnerAgencyPermission: 'PartnerAgencyPermission',
    PaymentInformation: 'PaymentInformation',
    PaymentPackageStatus: 'PaymentPackageStatus',
    PricingTable: 'PricingTable',
    RefreshProfileAccountAvailability: 'RefreshProfileAccountAvailability',
    RefreshTwitterAccountAvailability: 'RefreshTwitterAccountAvailability',
    RequestedBcaBrands: 'RequestedBcaBrands',
    SearchEngCampaignsSelectInfluencers: 'SearchEngCampaignsSelectInfluencers',
    SearchPrivatePartnerInfluencers: 'SearchPrivatePartnerInfluencers',
    SearchPrivateTalentInfluencers: 'SearchPrivateTalentInfluencers',
    SelectableBcaBrands: 'SelectableBcaBrands',
    SocialAuthRedirectUrlForInfluencer: 'SocialAuthRedirectUrlForInfluencer',
    SponsoredPosts: 'SponsoredPosts',
    SponsoredPostsCount: 'SponsoredPostsCount',
    TalentAgency: 'TalentAgency',
    TalentAgencyBusinessAssociateCompanies: 'TalentAgencyBusinessAssociateCompanies',
    TalentAgencyReconnectAnalyticsSocialAuthRedirectUrl: 'TalentAgencyReconnectAnalyticsSocialAuthRedirectUrl',
    TalentInfluencerDetailsForEdit: 'TalentInfluencerDetailsForEdit',
    TiktokAdAccounts: 'TiktokAdAccounts',
    TiktokAdAuthRedirectUrl: 'TiktokAdAuthRedirectUrl',
    TiktokAdCampaigns: 'TiktokAdCampaigns',
    TiktokAdGroups: 'TiktokAdGroups',
    TiktokAdvertiserAccountInfo: 'TiktokAdvertiserAccountInfo',
    TiktokAdvertiserPlanData: 'TiktokAdvertiserPlanData',
    TiktokAdvertiserSummaryData: 'TiktokAdvertiserSummaryData',
    TiktokBudgetAllocation: 'TiktokBudgetAllocation',
    TiktokBusinessAccountInfo: 'TiktokBusinessAccountInfo',
    TiktokBusinessAdAccounts: 'TiktokBusinessAdAccounts',
    TiktokProfileAudience: 'TiktokProfileAudience',
    TiktokSpecialCampaign: 'TiktokSpecialCampaign',
    TiktokSpecialCampaignPosts: 'TiktokSpecialCampaignPosts',
    TiktokSpecialCampaignReport: 'TiktokSpecialCampaignReport',
    TiktokSpecialCampaignReportGrid: 'TiktokSpecialCampaignReportGrid',
    TiktokSpecialCampaignReportSummary: 'TiktokSpecialCampaignReportSummary',
    TiktokSpecialPackageInfluencers: 'TiktokSpecialPackageInfluencers',
    TiktokSpecialPost: 'TiktokSpecialPost',
    TiktokSpecialPostHistory: 'TiktokSpecialPostHistory',
    TiktokTagPostDetails: 'TiktokTagPostDetails',
    TiktokUserHashtagPostList: 'TiktokUserHashtagPostList',
    TiktokUserHashtags: 'TiktokUserHashtags',
    TiktokUserKeywordPostList: 'TiktokUserKeywordPostList',
    TiktokUserKeywords: 'TiktokUserKeywords',
    TrendingFacebookInfluencers: 'TrendingFacebookInfluencers',
    TrendingInstagramInfluencersV2: 'TrendingInstagramInfluencersV2',
    TrendingYoutubeInfluencersV2: 'TrendingYoutubeInfluencersV2',
    TwitterAnalyticsOverview: 'TwitterAnalyticsOverview',
    TwitterAnalyticsPost: 'TwitterAnalyticsPost',
    TwitterAnalyticsPosts: 'TwitterAnalyticsPosts',
    TwitterAnalyticsPostsByDate: 'TwitterAnalyticsPostsByDate',
    TwitterAnalyticsPostsStatistic: 'TwitterAnalyticsPostsStatistic',
    UpdatePaymentMethodLink: 'UpdatePaymentMethodLink',
    User: 'User',
    UserProposal: 'UserProposal',
    WordCloudList: 'WordCloudList',
    XhsAccountInterest: 'XhsAccountInterest',
    XhsProfileAudience: 'XhsProfileAudience',
    XhsProfileEngagementInsights: 'XhsProfileEngagementInsights',
    XhsProfilePosts: 'XhsProfilePosts',
    YoutubeAnalyticsAudience: 'YoutubeAnalyticsAudience',
    YoutubeAnalyticsComments: 'YoutubeAnalyticsComments',
    YoutubeAnalyticsCompareAccountCards: 'YoutubeAnalyticsCompareAccountCards',
    YoutubeAnalyticsCompareRelatedPosts: 'YoutubeAnalyticsCompareRelatedPosts',
    YoutubeAnalyticsCountriesCategories: 'YoutubeAnalyticsCountriesCategories',
    YoutubeAnalyticsOverview: 'YoutubeAnalyticsOverview',
    YoutubeAnalyticsPost: 'YoutubeAnalyticsPost',
    YoutubeAnalyticsPosts: 'YoutubeAnalyticsPosts',
    YoutubeAnalyticsPostsByDate: 'YoutubeAnalyticsPostsByDate',
    YoutubeAnalyticsPostsStatistics: 'YoutubeAnalyticsPostsStatistics',
    YoutubeAnalyticsRelatedPosts: 'YoutubeAnalyticsRelatedPosts',
    YoutubeAnalyticsShorts: 'YoutubeAnalyticsShorts',
    YoutubeAnalyticsTagsTopRanking: 'YoutubeAnalyticsTagsTopRanking',
    YoutubeAnalyticsTagsTopRankingCount: 'YoutubeAnalyticsTagsTopRankingCount',
    YoutubeAnalyticsTrendPostById: 'YoutubeAnalyticsTrendPostById',
    YoutubeAnalyticsTrends: 'YoutubeAnalyticsTrends',
    YoutubeBrandAccounts: 'YoutubeBrandAccounts',
    YoutubeCmsChannelDetail: 'YoutubeCmsChannelDetail',
    YoutubeCmsChannelInfo: 'YoutubeCmsChannelInfo',
    YoutubeCmsConfirmedAssetsSummary: 'YoutubeCmsConfirmedAssetsSummary',
    YoutubeCmsConfirmedChannelsSummary: 'YoutubeCmsConfirmedChannelsSummary',
    YoutubeCmsConfirmedRevenueLatestMonth: 'YoutubeCmsConfirmedRevenueLatestMonth',
    YoutubeCmsEstimateAssetsSummary: 'YoutubeCmsEstimateAssetsSummary',
    YoutubeCmsEstimateChannelsSummary: 'YoutubeCmsEstimateChannelsSummary',
    YoutubeCmsInvoiceList: 'YoutubeCmsInvoiceList',
    YoutubeCompareAllPostsInDate: 'YoutubeCompareAllPostsInDate',
    YoutubeCompareOverview: 'YoutubeCompareOverview',
    YoutubeComparePostById: 'YoutubeComparePostById',
    YoutubeComparePostList: 'YoutubeComparePostList',
    YoutubeComparePosts: 'YoutubeComparePosts',
    YoutubeCompareTagRanking: 'YoutubeCompareTagRanking'
  },
  Mutation: {
    AddAllSelectedInfluencers: 'AddAllSelectedInfluencers',
    AddInfluencerToUserProposal: 'AddInfluencerToUserProposal',
    AddInstagramCompareAccountAnalytics: 'AddInstagramCompareAccountAnalytics',
    AddInstagramHashtagAnalytics: 'AddInstagramHashtagAnalytics',
    AddSelectedInfluencers: 'AddSelectedInfluencers',
    AddSocialAccountToUserProposal: 'AddSocialAccountToUserProposal',
    AddStaffAdvertisersToAnotherStaff: 'AddStaffAdvertisersToAnotherStaff',
    AddYoutubeCompareAccountAnalytics: 'AddYoutubeCompareAccountAnalytics',
    AllowEngagementInfluencerProposalList: 'AllowEngagementInfluencerProposalList',
    AllowInfluencersUserProposal: 'AllowInfluencersUserProposal',
    ApproveApplicantInfluencers: 'ApproveApplicantInfluencers',
    ApproveAutoManagedPayout: 'ApproveAutoManagedPayout',
    ApproveCmsInvoice: 'ApproveCmsInvoice',
    ApproveMarketplace: 'ApproveMarketplace',
    ApproveMarketplaceAffiliatePayout: 'ApproveMarketplaceAffiliatePayout',
    ApproveMarketplaceDraftPost: 'ApproveMarketplaceDraftPost',
    ApproveTiktokSpecialCampaign: 'ApproveTiktokSpecialCampaign',
    AssignAdvertiserToStaffs: 'AssignAdvertiserToStaffs',
    AuthTiktokBusinessAccount: 'AuthTiktokBusinessAccount',
    BoostTiktokAdPost: 'BoostTiktokAdPost',
    BulkUpdateEngagementInfluencersBudget: 'BulkUpdateEngagementInfluencersBudget',
    BulkUpdateEngagementSocialAccountsBudget: 'BulkUpdateEngagementSocialAccountsBudget',
    BulkUpdatePackageProposalAccountsBudget: 'BulkUpdatePackageProposalAccountsBudget',
    BulkUpdatePackageProposalInfluencersBudget: 'BulkUpdatePackageProposalInfluencersBudget',
    BulkUpdateUserProposalAccountsBudget: 'BulkUpdateUserProposalAccountsBudget',
    BulkUpdateUserProposalInfluencersBudget: 'BulkUpdateUserProposalInfluencersBudget',
    CancelSubscriptionPlan: 'CancelSubscriptionPlan',
    ChangeInstagramUGCManagementPostStatus: 'ChangeInstagramUGCManagementPostStatus',
    ClearCmsChannelAssetOwner: 'ClearCmsChannelAssetOwner',
    CloneMarketplace: 'CloneMarketplace',
    ConnectPackageToEngagementProposal: 'ConnectPackageToEngagementProposal',
    ConnectPackageWithInfluencerAccounts: 'ConnectPackageWithInfluencerAccounts',
    ConnectPackageWithSocialAccounts: 'ConnectPackageWithSocialAccounts',
    ConnectPackageWithUserProposal: 'ConnectPackageWithUserProposal',
    CreateAdminUser: 'CreateAdminUser',
    CreateAdvertiserUser: 'CreateAdvertiserUser',
    CreateAdvertiserWithUserV2: 'CreateAdvertiserWithUserV2',
    CreateAgencyUser: 'CreateAgencyUser',
    CreateAgencyWithUser: 'CreateAgencyWithUser',
    CreateDraftBoostPost: 'CreateDraftBoostPost',
    CreateEngagement: 'CreateEngagement',
    CreateEngagementPostV2: 'CreateEngagementPostV2',
    CreateGeminiThread: 'CreateGeminiThread',
    CreateInfluencerV2: 'CreateInfluencerV2',
    CreateInstagramUGCManagementHashtag: 'CreateInstagramUGCManagementHashtag',
    CreateInstagramUserHashtag: 'CreateInstagramUserHashtag',
    CreateMarketplace: 'CreateMarketplace',
    CreateOpenAiThread: 'CreateOpenAiThread',
    CreatePackage: 'CreatePackage',
    CreatePackageWithInfluencerAccounts: 'CreatePackageWithInfluencerAccounts',
    CreatePackageWithSocialAccounts: 'CreatePackageWithSocialAccounts',
    CreatePackageWithUserProposal: 'CreatePackageWithUserProposal',
    CreatePartnerUser: 'CreatePartnerUser',
    CreateSelfRegisteredAdvertiserUser: 'CreateSelfRegisteredAdvertiserUser',
    CreateSocialAccountAnalyticsAdminV2: 'CreateSocialAccountAnalyticsAdminV2',
    CreateSocialAccountAnalyticsAdvertiserV2: 'CreateSocialAccountAnalyticsAdvertiserV2',
    CreateStaffUser: 'CreateStaffUser',
    CreateTalentAgencyUser: 'CreateTalentAgencyUser',
    CreateTalentAgencyWithUser: 'CreateTalentAgencyWithUser',
    CreateTalentInfluencer: 'CreateTalentInfluencer',
    CreateTiktokAdAccount: 'CreateTiktokAdAccount',
    CreateTiktokBusinessCenter: 'CreateTiktokBusinessCenter',
    CreateTiktokSpecialCampaign: 'CreateTiktokSpecialCampaign',
    CreateTiktokUserHashtag: 'CreateTiktokUserHashtag',
    CreateTiktokUserKeyword: 'CreateTiktokUserKeyword',
    DeleteAdvertiser: 'DeleteAdvertiser',
    DeleteAgency: 'DeleteAgency',
    DeleteBrandAccountAnalytics: 'DeleteBrandAccountAnalytics',
    DeleteEngagement: 'DeleteEngagement',
    DeleteEngagementInfluencerProposal: 'DeleteEngagementInfluencerProposal',
    DeleteEngagementProposalJoinedAccount: 'DeleteEngagementProposalJoinedAccount',
    DeleteEngagementSocialAccountProposal: 'DeleteEngagementSocialAccountProposal',
    DeleteGeminiThread: 'DeleteGeminiThread',
    DeleteInfluencer: 'DeleteInfluencer',
    DeleteInfluencerPackage: 'DeleteInfluencerPackage',
    DeleteInstagramCompareAccountAnalytics: 'DeleteInstagramCompareAccountAnalytics',
    DeleteInstagramHashtagAnalytics: 'DeleteInstagramHashtagAnalytics',
    DeleteInstagramUGCManagementHashtag: 'DeleteInstagramUGCManagementHashtag',
    DeleteInstagramUserHashtag: 'DeleteInstagramUserHashtag',
    DeleteMarketplace: 'DeleteMarketplace',
    DeleteOpenAiThread: 'DeleteOpenAiThread',
    DeletePackage: 'DeletePackage',
    DeleteSocialAccountPackage: 'DeleteSocialAccountPackage',
    DeleteTalentAgency: 'DeleteTalentAgency',
    DeleteTiktokUserHashtag: 'DeleteTiktokUserHashtag',
    DeleteTiktokUserKeyword: 'DeleteTiktokUserKeyword',
    DeleteUser: 'DeleteUser',
    DeleteUserProposalInfluencer: 'DeleteUserProposalInfluencer',
    DeleteUserProposalSocialAccount: 'DeleteUserProposalSocialAccount',
    DeleteUserProposalSocialAccountIndividually: 'DeleteUserProposalSocialAccountIndividually',
    DeleteYoutubeCompareAccountAnalytics: 'DeleteYoutubeCompareAccountAnalytics',
    DenyEngagementInfluencerProposalList: 'DenyEngagementInfluencerProposalList',
    DenyInfluencersUserProposal: 'DenyInfluencersUserProposal',
    DisconnectPackageFromEngagementProposal: 'DisconnectPackageFromEngagementProposal',
    DisconnectTiktokAdAccount: 'DisconnectTiktokAdAccount',
    EditSocialAccountAnalyticsAdminV2: 'EditSocialAccountAnalyticsAdminV2',
    EditSocialAccountAnalyticsAdvertiserV2: 'EditSocialAccountAnalyticsAdvertiserV2',
    EngagementAddInfluencersToProposalFromAnotherCampaign: 'EngagementAddInfluencersToProposalFromAnotherCampaign',
    EngagementCampaignCouponTracking: 'EngagementCampaignCouponTracking',
    ExportCmsInvoiceToSpreadsheet: 'ExportCmsInvoiceToSpreadsheet',
    ExportInstagramAccountDashboardAnalytics: 'ExportInstagramAccountDashboardAnalytics',
    ExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet:
      'ExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet',
    ExportInstagramHashtagPostsToGoogleSpreadsheet: 'ExportInstagramHashtagPostsToGoogleSpreadsheet',
    ExportTiktokHashtagPostsToGoogleSpreadsheet: 'ExportTiktokHashtagPostsToGoogleSpreadsheet',
    ExportTiktokKeywordPostsToGoogleSpreadsheet: 'ExportTiktokKeywordPostsToGoogleSpreadsheet',
    ExportYoutubeDashboardAccountAnalytics: 'ExportYoutubeDashboardAccountAnalytics',
    FetchAutoManagedCustomShopeeCampaigns: 'FetchAutoManagedCustomShopeeCampaigns',
    GenerateMarketplaceCampaignDetail: 'GenerateMarketplaceCampaignDetail',
    GeneratePartnerInfluencerSignUpUrl: 'GeneratePartnerInfluencerSignUpUrl',
    GenerateTalentInfluencerSignUpUrl: 'GenerateTalentInfluencerSignUpUrl',
    InfluencerAddYoutubeCompareAccountAnalytics: 'InfluencerAddYoutubeCompareAccountAnalytics',
    InfluencerAnalyticsTwitterRefresh: 'InfluencerAnalyticsTwitterRefresh',
    InfluencerDeleteYoutubeCompareAccountAnalytics: 'InfluencerDeleteYoutubeCompareAccountAnalytics',
    InfluencerSocialAuthReconnectInstagramAccountAnalytics: 'InfluencerSocialAuthReconnectInstagramAccountAnalytics',
    JoinMarketplaceByStaff: 'JoinMarketplaceByStaff',
    LinkCmsChannelAssets: 'LinkCmsChannelAssets',
    MarketplaceCampaignCouponTracking: 'MarketplaceCampaignCouponTracking',
    ReSendBcaRequest: 'ReSendBcaRequest',
    RechargeAdvertiserAccount: 'RechargeAdvertiserAccount',
    ReconnectTiktokAdAccount: 'ReconnectTiktokAdAccount',
    RefreshPassword: 'RefreshPassword',
    RefreshProfileAccount: 'RefreshProfileAccount',
    RefreshTwitterAccount: 'RefreshTwitterAccount',
    RejectEngagementDraftPost: 'RejectEngagementDraftPost',
    RejectMarketplaceCampaign: 'RejectMarketplaceCampaign',
    RejectMarketplaceDraftPost: 'RejectMarketplaceDraftPost',
    RejectTiktokSpecialCampaign: 'RejectTiktokSpecialCampaign',
    RemoveAllSelectedInfluencers: 'RemoveAllSelectedInfluencers',
    RemoveSelectedInfluencers: 'RemoveSelectedInfluencers',
    ReorderMarketplaceCampaigns: 'ReorderMarketplaceCampaigns',
    ReportEngagementPostStatus: 'ReportEngagementPostStatus',
    ReportMarketplacePostStatus: 'ReportMarketplacePostStatus',
    ReportTiktokSpecialPostStatus: 'ReportTiktokSpecialPostStatus',
    RequestGoogleSlideCustomisedReport: 'RequestGoogleSlideCustomisedReport',
    RequestInfluencerProfilePptx: 'RequestInfluencerProfilePptx',
    RequestInfluencersProfilePptx: 'RequestInfluencersProfilePptx',
    RequestInfluencersSocialAccountPptx: 'RequestInfluencersSocialAccountPptx',
    RequestInstagramUGCManagementPost: 'RequestInstagramUGCManagementPost',
    RequestPackageProposalExcel: 'RequestPackageProposalExcel',
    RequestPackageProposalPptx: 'RequestPackageProposalPptx',
    RequestPackageSpreadsheet: 'RequestPackageSpreadsheet',
    RequestPowerpointCustomisedReport: 'RequestPowerpointCustomisedReport',
    RequestProposalExcel: 'RequestProposalExcel',
    RequestProposalPptx: 'RequestProposalPptx',
    RequestProposalSpreadsheet: 'RequestProposalSpreadsheet',
    RequestUserProposalExcel: 'RequestUserProposalExcel',
    RequestUserProposalPptx: 'RequestUserProposalPptx',
    ResendEmailForAdvertiser: 'ResendEmailForAdvertiser',
    ResetPassword: 'ResetPassword',
    ResetPasswordAndLogin: 'ResetPasswordAndLogin',
    SendBcaRequest: 'SendBcaRequest',
    SendGeminiMessage: 'SendGeminiMessage',
    SendOpenAiMessage: 'SendOpenAiMessage',
    SocialAuthConnectableFacebookPageAnalytics: 'SocialAuthConnectableFacebookPageAnalytics',
    SocialAuthConnectableInstagramAccountsAnalytics: 'SocialAuthConnectableInstagramAccountsAnalytics',
    SocialAuthConnectableTwitterAccountAnalyticsV2: 'SocialAuthConnectableTwitterAccountAnalyticsV2',
    SocialAuthConnectableYouTubeChannelsAnalyticsV2: 'SocialAuthConnectableYouTubeChannelsAnalyticsV2',
    SocialAuthReconnectFacebookPageAccountAnalytics: 'SocialAuthReconnectFacebookPageAccountAnalytics',
    SocialAuthReconnectInstagramAccountAnalytics: 'SocialAuthReconnectInstagramAccountAnalytics',
    SocialAuthReconnectTwitterAccountAnalyticsV2: 'SocialAuthReconnectTwitterAccountAnalyticsV2',
    SocialAuthReconnectYouTubeAccountAnalyticsV2: 'SocialAuthReconnectYouTubeAccountAnalyticsV2',
    SocialAuthTalentSignInFacebook: 'SocialAuthTalentSignInFacebook',
    SocialAuthTalentSignInInstagram: 'SocialAuthTalentSignInInstagram',
    SocialAuthTalentSignInTwitter: 'SocialAuthTalentSignInTwitter',
    SocialAuthTalentSignInYouTube: 'SocialAuthTalentSignInYouTube',
    SubscribeAnalyticsPlan: 'SubscribeAnalyticsPlan',
    SubscribeAnalyticsTrialPlan: 'SubscribeAnalyticsTrialPlan',
    SudoLimitedLogin: 'SudoLimitedLogin',
    SwitchRoleToAdmin: 'SwitchRoleToAdmin',
    SwitchRoleToAdvertiser: 'SwitchRoleToAdvertiser',
    SwitchRoleToAgency: 'SwitchRoleToAgency',
    SwitchRoleToPartner: 'SwitchRoleToPartner',
    SwitchRoleToStaff: 'SwitchRoleToStaff',
    SwitchRoleToTalentAgency: 'SwitchRoleToTalentAgency',
    TokenAuth: 'TokenAuth',
    TransferMoneyToAdAccount: 'TransferMoneyToAdAccount',
    UnApproveApplicantInfluencers: 'UnApproveApplicantInfluencers',
    UnblockInfluencerMarketplace: 'UnblockInfluencerMarketplace',
    UpdateAdminUser: 'UpdateAdminUser',
    UpdateAdvertiser: 'UpdateAdvertiser',
    UpdateAdvertiserUser: 'UpdateAdvertiserUser',
    UpdateAdvertiserUserSettings: 'UpdateAdvertiserUserSettings',
    UpdateAgency: 'UpdateAgency',
    UpdateAgencyUser: 'UpdateAgencyUser',
    UpdateAutoManagedCampaign: 'UpdateAutoManagedCampaign',
    UpdateAutoManagedCampaignsStatus: 'UpdateAutoManagedCampaignsStatus',
    UpdateCmsAssetShareRate: 'UpdateCmsAssetShareRate',
    UpdateCmsChannelShareRate: 'UpdateCmsChannelShareRate',
    UpdateEngagement: 'UpdateEngagement',
    UpdateEngagementBreakdownMode: 'UpdateEngagementBreakdownMode',
    UpdateEngagementCouponList: 'UpdateEngagementCouponList',
    UpdateEngagementInfluencerProposalList: 'UpdateEngagementInfluencerProposalList',
    UpdateEngagementInfluencersBudget: 'UpdateEngagementInfluencersBudget',
    UpdateEngagementPostStatus: 'UpdateEngagementPostStatus',
    UpdateEngagementPostV2: 'UpdateEngagementPostV2',
    UpdateEngagementSocialAccountProposalList: 'UpdateEngagementSocialAccountProposalList',
    UpdateEngagementSocialAccountsBudget: 'UpdateEngagementSocialAccountsBudget',
    UpdateEngagementStatus: 'UpdateEngagementStatus',
    UpdateInfluencerRateCardsByStaff: 'UpdateInfluencerRateCardsByStaff',
    UpdateInfluencerTagsByStaff: 'UpdateInfluencerTagsByStaff',
    UpdateInfluencerV2: 'UpdateInfluencerV2',
    UpdateInternalMemo: 'UpdateInternalMemo',
    UpdateMarketplace: 'UpdateMarketplace',
    UpdateMarketplaceCouponList: 'UpdateMarketplaceCouponList',
    UpdateMarketplaceInfluencerCost: 'UpdateMarketplaceInfluencerCost',
    UpdateMarketplacePostStatusV2: 'UpdateMarketplacePostStatusV2',
    UpdatePackage: 'UpdatePackage',
    UpdatePackageProposalInfluencersBudget: 'UpdatePackageProposalInfluencersBudget',
    UpdatePackageProposalMode: 'UpdatePackageProposalMode',
    UpdatePackageProposalSocialAccountsBudget: 'UpdatePackageProposalSocialAccountsBudget',
    UpdatePartnerAgency: 'UpdatePartnerAgency',
    UpdatePartnerUser: 'UpdatePartnerUser',
    UpdatePasswordByAdvertiserUser: 'UpdatePasswordByAdvertiserUser',
    UpdatePaymentStatus: 'UpdatePaymentStatus',
    UpdateSocialAccounts: 'UpdateSocialAccounts',
    UpdateSocialUsername: 'UpdateSocialUsername',
    UpdateStaffUser: 'UpdateStaffUser',
    UpdateSubscriptionPlan: 'UpdateSubscriptionPlan',
    UpdateTalentAgency: 'UpdateTalentAgency',
    UpdateTalentAgencyUser: 'UpdateTalentAgencyUser',
    UpdateTalentInfluencer: 'UpdateTalentInfluencer',
    UpdateTiktokAdvertiserInfo: 'UpdateTiktokAdvertiserInfo',
    UpdateTiktokSpecialCampaign: 'UpdateTiktokSpecialCampaign',
    UpdateTiktokSpecialPostStatus: 'UpdateTiktokSpecialPostStatus',
    UpdateTtcmPost: 'UpdateTtcmPost',
    UpdateUserProposalBreakdownMode: 'UpdateUserProposalBreakdownMode',
    UpdateUserProposalInfluencersBudget: 'UpdateUserProposalInfluencersBudget',
    UpdateUserProposalSocialAccountsBudget: 'UpdateUserProposalSocialAccountsBudget',
    UpdateYoutubeMCNInfluencerCredentials: 'UpdateYoutubeMCNInfluencerCredentials',
    UpdateYoutubeMCNInfluencerCredentialsForAdmin: 'UpdateYoutubeMCNInfluencerCredentialsForAdmin',
    UploadEngagementReportFile: 'UploadEngagementReportFile',
    UploadEngagementReportFromInsightCheck: 'UploadEngagementReportFromInsightCheck',
    UploadEngagementXhsInsightCheck: 'UploadEngagementXhsInsightCheck',
    VerifyToken: 'VerifyToken',
    WithdrawMoneyFromAdAccount: 'WithdrawMoneyFromAdAccount'
  },
  Fragment: {
    ProposalSummary: 'ProposalSummary',
    PostsMetricsGrid: 'PostsMetricsGrid',
    FacebookPagePostData: 'FacebookPagePostData',
    FacebookPageStatsData: 'FacebookPageStatsData',
    CampaignStat: 'CampaignStat',
    InstagramPostData: 'InstagramPostData',
    InfluencerProfileInstagramAccount: 'InfluencerProfileInstagramAccount',
    InfluencerProfileSocialAccount: 'InfluencerProfileSocialAccount',
    InfluencerProfileYoutubeAccount: 'InfluencerProfileYoutubeAccount',
    overviewItem: 'overviewItem',
    overviewTotalItem: 'overviewTotalItem',
    overviewRealTotalItem: 'overviewRealTotalItem',
    SocialAccountParams: 'SocialAccountParams',
    YoutubePostData: 'YoutubePostData',
    HistoryDataPoint: 'HistoryDataPoint',
    SummaryData: 'SummaryData',
    MainAccountPostInDateData: 'MainAccountPostInDateData',
    IgGenderRateDetails: 'IgGenderRateDetails',
    IgAgeRateDetails: 'IgAgeRateDetails',
    HistoryData: 'HistoryData',
    IgSummaryData: 'IgSummaryData',
    IgAverageSummaryData: 'IgAverageSummaryData',
    PostData: 'PostData',
    IgHashtagSummaryData: 'IgHashtagSummaryData',
    IgAnalyticsHashtagData: 'IgAnalyticsHashtagData',
    IgInteractionSummaryData: 'IgInteractionSummaryData',
    CampaignStats: 'CampaignStats',
    OpenAIMessageValues: 'OpenAIMessageValues',
    TiktokSpecialStatsItemMetrics: 'TiktokSpecialStatsItemMetrics',
    TikTokPostsMetricsGrid: 'TikTokPostsMetricsGrid',
    TiktokTagPostItem: 'TiktokTagPostItem',
    EngagementInfo: 'EngagementInfo',
    UserProposalSummary: 'UserProposalSummary',
    YoutubeAccountPostInDateData: 'YoutubeAccountPostInDateData',
    StatsData: 'StatsData'
  }
};
