import { DownloadFileType } from '@/shared/types';
import { getAccountManagementApiUrl } from '@/shared/utils';
import { AnalyticsSelectedAccountInfo } from '@/shared/organisms';
import { DownloadButton, DownloadButtonProps } from '@/shared/atoms';
import { ExportTagsModalProps, ExportTagsModal } from '@/shared/molecules';
import { useToggleState, useDownloadFile, useQueryHelper } from '@/shared/hooks';
import { useExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetMutation } from '@/graphql';

interface Props {
  hashtagId: string;
  analyticsAccountId: AnalyticsSelectedAccountInfo['id'];
}

export const useExportInstagramAnalyticsProfileHashtagPosts = ({ hashtagId, analyticsAccountId }: Props) => {
  const exportModal = useToggleState();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { downloading, handleDownloadFile } = useDownloadFile();
  const { loading, callExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet } =
    useExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheetMutation();

  const handleExportFile: ExportTagsModalProps['onSubmit'] = async ({ type, endDate, startDate }) => {
    try {
      const isSupportedType = [DownloadFileType.CSV, DownloadFileType.EXCEL, DownloadFileType.SPREADSHEET];

      if (!hashtagId || !isSupportedType) return;

      if (type === DownloadFileType.SPREADSHEET) {
        await callExportInstagramAnalyticsProfileHashtagPostsToGoogleSpreadsheet({
          variables: {
            input: {
              hashtagId: +hashtagId,
              endDate: endDate.toString(),
              startDate: startDate.toString(),
              instagramAnalyticsAccountId: analyticsAccountId
            }
          }
        });
        enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
      } else {
        await handleDownloadFile({
          method: 'GET',
          filenamePrefix: `hashtags-analytics-profile-${new Date().toUTCString()}`,
          params: { endDate, startDate, instagramAnalyticsAccountId: analyticsAccountId },
          url: getAccountManagementApiUrl(
            `api/analytics/instagram/analytics_profile/hashtag/${type === DownloadFileType.EXCEL ? 'xlsx' : 'csv'}/${hashtagId}`
          )
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message || t('failedToDownload'), { variant: 'error' });
    } finally {
      exportModal.close();
    }
  };

  return {
    ExportButton: (props: DownloadButtonProps) => (
      <DownloadButton onClick={exportModal.open} title={t('Button.HashTag Export')} {...props} />
    ),
    ExportModal: (
      <ExportTagsModal
        open={exportModal.status}
        onClose={exportModal.close}
        onSubmit={handleExportFile}
        downloading={downloading || loading}
      />
    )
  };
};
