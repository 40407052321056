import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { THEME } from '@/shared/constants';
import { FormStyle } from '@/shared/atoms';
import { TextSearchField, BasicFilterWrapper, SingleDatePickerField } from '@/shared/molecules';
import { AnalyticsSelectedAccountInfo } from '../../../types';
import { AnalyticsHashtagsList, AnalyticsHashtagsListProps } from '../../shared';
import { useCreateDeleteInstagramHashtag, useExportInstagramAnalyticsProfileHashtagPosts } from './hooks';
import { AnalyticsInstagramHashtagsFilterFormValues, AnalyticsInstagramHashtagsFilterFormKeys } from './schemaTypes';

export interface TemplateProps extends Pick<AnalyticsHashtagsListProps, 'hashtags' | 'maxHashtags'> {
  onSubmit?: () => void;
  analyticsAccountId: AnalyticsSelectedAccountInfo['id'];
}

export const Template = ({ hashtags, maxHashtags, analyticsAccountId, onSubmit }: TemplateProps) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AnalyticsInstagramHashtagsFilterFormValues>();
  const { adding, deleting, handleAddNewHashtag, handleDeleteHashtag } = useCreateDeleteInstagramHashtag({
    hashtags,
    onSubmit,
    analyticsAccountId
  });

  const [dateValue, hashtagIdValue] = watch(['date', 'hashtagId']);
  const { ExportButton, ExportModal } = useExportInstagramAnalyticsProfileHashtagPosts({
    analyticsAccountId,
    hashtagId: hashtagIdValue
  });

  return (
    <>
      <div css={{ padding: '16px', boxSizing: 'border-box', borderBottom: THEME.border.base }}>
        <AnalyticsHashtagsList
          adding={adding}
          deleting={deleting}
          hashtags={hashtags}
          maxHashtags={maxHashtags}
          activeHashtag={hashtagIdValue}
          onAddNewHashtag={handleAddNewHashtag}
          onDeleteHashtag={handleDeleteHashtag}
          onSelectHashtag={(hashtag) => {
            setValue('hashtagId', hashtag ? hashtag.id : '');
            onSubmit?.();
          }}
        />

        <BasicFilterWrapper css={{ padding: 0, marginTop: '8px' }}>
          <TextSearchField<AnalyticsInstagramHashtagsFilterFormKeys>
            name="keyword"
            onSubmit={onSubmit}
            placeholder={t<string>('hashtagsAnalyticsKeywordPlaceholder')}
          />

          <SingleDatePickerField<AnalyticsInstagramHashtagsFilterFormKeys>
            name="date"
            onSubmit={onSubmit}
            disabledDays={{ after: new Date() }}
            css={{ flex: 'none', width: '184px' }}
            required
            clearable={false}
          />

          <FormStyle.FieldWrapper css={{ flex: 'none' }}>
            <ExportButton disabled={!dateValue || !hashtagIdValue} />
          </FormStyle.FieldWrapper>
        </BasicFilterWrapper>
      </div>

      {ExportModal}
    </>
  );
};
